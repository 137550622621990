// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-item-js": () => import("/opt/build/repo/src/templates/newsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("/opt/build/repo/src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-challenging-driving-conditions-and-hgv-safe-loading-js": () => import("/opt/build/repo/src/pages/challenging-driving-conditions-and-hgv-safe-loading.js" /* webpackChunkName: "component---src-pages-challenging-driving-conditions-and-hgv-safe-loading-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("/opt/build/repo/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-courses-js": () => import("/opt/build/repo/src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-driver-compliance-js": () => import("/opt/build/repo/src/pages/driver-compliance.js" /* webpackChunkName: "component---src-pages-driver-compliance-js" */),
  "component---src-pages-driver-essentials-js": () => import("/opt/build/repo/src/pages/driver-essentials.js" /* webpackChunkName: "component---src-pages-driver-essentials-js" */),
  "component---src-pages-driver-information-js": () => import("/opt/build/repo/src/pages/driver-information.js" /* webpackChunkName: "component---src-pages-driver-information-js" */),
  "component---src-pages-driver-welfare-customer-care-and-security-js": () => import("/opt/build/repo/src/pages/driver-welfare-customer-care-and-security.js" /* webpackChunkName: "component---src-pages-driver-welfare-customer-care-and-security-js" */),
  "component---src-pages-haulier-information-js": () => import("/opt/build/repo/src/pages/haulier-information.js" /* webpackChunkName: "component---src-pages-haulier-information-js" */),
  "component---src-pages-health-and-safety-and-challenging-driving-conditions-js": () => import("/opt/build/repo/src/pages/health-and-safety-and-challenging-driving-conditions.js" /* webpackChunkName: "component---src-pages-health-and-safety-and-challenging-driving-conditions-js" */),
  "component---src-pages-health-and-safety-and-lgv-safe-loading-js": () => import("/opt/build/repo/src/pages/health-and-safety-and-lgv-safe-loading.js" /* webpackChunkName: "component---src-pages-health-and-safety-and-lgv-safe-loading-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-periodic-driver-cpc-training-js": () => import("/opt/build/repo/src/pages/periodic-driver-cpc-training.js" /* webpackChunkName: "component---src-pages-periodic-driver-cpc-training-js" */),
  "component---src-pages-traffic-law-incidents-and-vulnerable-road-users-js": () => import("/opt/build/repo/src/pages/traffic-law-incidents-and-vulnerable-road-users.js" /* webpackChunkName: "component---src-pages-traffic-law-incidents-and-vulnerable-road-users-js" */)
}

